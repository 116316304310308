import React, { useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { ptBR } from 'date-fns/locale';
import './BirthdayForm.css';
import logo from './logo.png';

const BirthdayForm = () => {
  const [nome, setNome] = useState("");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nome && dataNascimento) {
      const formattedDate = dataNascimento instanceof Date
        ? dataNascimento.toISOString().split('T')[0]
        : null;

      const userData = {
        nome, 
        data_nascimento: formattedDate
      };
      
      try {
        await axios.post('https://ipbitauna.com.br/api/usuarios', userData);

        setSuccessMessage(`Obrigado por se cadastrar, ${nome}! Seus dados foram salvos com sucesso.`);
      } catch (error) {
        console.error('Erro ao salvar dados:', error);
        alert('Ocorreu um erro ao salvar seus dados. Por favor, tente novamente.');
      }
      
      setNome("");
      setDataNascimento(null);
    } else {
      alert("Por favor, preencha todos os campos.");
    }
  };

  return (
    <div className="form-container">
      <img src={logo} alt="Logo da Igreja Presbiteriana" className="logo" />
      <h1 className="form-title">Cadastro de Aniversariantes</h1>
      
      {successMessage && <div className="success-message">{successMessage}</div>}

      <form onSubmit={handleSubmit} className="form">
        <label className="form-label">
          Nome completo:
          <input
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            placeholder="Digite seu nome completo"
            className="form-input"
            required
          />
        </label>
        <label className="form-label">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <DatePicker
              label="Data de nascimento"
              value={dataNascimento}
              onChange={(newDate) => setDataNascimento(newDate)}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  className="form-input" 
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD/MM/AAAA",
                  }}
                />
              )}
              inputFormat="dd/MM/yyyy"
              disableFuture
              openTo="year"
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </label>
        <button type="submit" className="form-button">Enviar</button>
      </form>
    </div>
  );
};

export default BirthdayForm;
